import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeBuyOrder, makeSellOrder } from '../store/interactions'

const Order = () => {
  const [isBuy, setIsBuy] = useState(true)
  const [activeAmount, setActiveAmount] = useState(false)
  const [amount, setAmount] = useState(0)
  const [price, setPrice] = useState(0)
  const [priceInputErr, setPriceInputErr] = useState(null)

  const provider = useSelector(state => state.provider.connection)
  const tokens = useSelector(state => state.tokens.contracts)
  const exchange = useSelector(state => state.exchange.contract)

  const dispatch = useDispatch()

  const buyRef = useRef(null)
  const sellRef = useRef(null)

  const tabHandler = (e) => {
    if(e.target.className !== buyRef.current.className) {
      e.target.className = 'tab tab-sell--active'
      buyRef.current.className = 'tab'
      setIsBuy(false)
    } else {
      e.target.className = 'tab tab-buy--active'
      sellRef.current.className = 'tab'
      setIsBuy(true)
    }
  }

  const handlePriceChange = (e, activeAmount) => {
    const inputValue = e.target.value;

    // Kiểm tra nếu giá trị nhập vào là số và không vượt quá 7 chữ số 0 sau dấu chấm động
    const isValidInput = /^\d+(\.\d{0,5})?$/.test(inputValue);

    setActiveAmount(activeAmount ?? false)
    if (isValidInput || !inputValue) {
      activeAmount ? setAmount(inputValue) : setPrice(inputValue);
    } else {
      setPriceInputErr("Input value is too small")
      const timer = setInterval(() => {
        setPriceInputErr(null)
        clearInterval(timer)
      }, 2 * 1000)
    }
  };

  const validatorForm = (amount, price) => {
    if(!amount || !price) {
      setActiveAmount(!amount ? true : false)
      setPriceInputErr("Input value invalid")
      const timer = setInterval(() => {
        setPriceInputErr(null)
        setActiveAmount(false)
        clearInterval(timer)
      }, 2 * 1000)
      return false
    }
    return true
  }

  const buyHandler = (e) => {
    e.preventDefault()
    if(!validatorForm(amount, price)) {
      return
    }
    makeBuyOrder(provider, exchange, tokens, { amount, price }, dispatch)
    setAmount(0)
    setPrice(0)
  }

  const sellHandler = (e) => {
    e.preventDefault()
    if(!validatorForm(amount, price)) {
      return
    }
    makeSellOrder(provider, exchange, tokens, { amount, price }, dispatch)
    setAmount(0)
    setPrice(0)
  }

  return (
    <div className="component exchange__orders">
      <div className='component__header flex-between'>
        <h2>New Order</h2>
        <div className='tabs'>
          <button onClick={tabHandler} ref={buyRef} className='tab tab-buy--active'>Buy</button>
          <button onClick={tabHandler} ref={sellRef} className='tab'>Sell</button>
        </div>
      </div>

      <form onSubmit={isBuy ? buyHandler : sellHandler }>

        {isBuy ? (
          <label htmlFor="amount">Buy Amount</label>
        ) : (
          <label htmlFor="amount">Sell Amount</label>
        )}

        <input
            type="text"
            id='amount'
            placeholder='0.0000'
            value={amount === 0 ? '' : amount}
            onChange={(e) => handlePriceChange(e, true)}
        />
        {priceInputErr && activeAmount && <p className="text-danger">{priceInputErr}</p>}

        {isBuy ? (
          <label htmlFor="price">Buy Price</label>
        ) : (
          <label htmlFor="price">Sell Price</label>
        )}

        <input
            type="text"
            id='price'
            placeholder='0.0000'
            value={price === 0 ? '' : price}
            onChange={handlePriceChange}
        />
        {priceInputErr && !activeAmount && <p className="text-danger">{priceInputErr}</p>}

        <button className={`button button-${isBuy ? 'buy' : 'sell'}--filled`} type='submit'>
          {isBuy ? (
              <span>Buy Order</span>
          ) : (
              <span>Sell Order</span>
          )}
        </button>
      </form>
    </div>
  );
}

export default Order;
