import { useState, useEffect } from "react";
import { connectWallet } from "../../utils/connectWallet";
import Web3Context from "../../context/Web3Context";
import Button from "../Button/Button";
import {
  handleAccountChange,
  handleChainChange,
} from "../../utils/connectWallet";
import { toast } from "react-hot-toast";
import "./Wallet.css";
import { useDispatch, useSelector } from "react-redux";
import { Contract } from "ethers";
import stakingAbi from "../../abis/Staking.json";
import stakeTokenAbi from "../../abis/StakeToken.json";
import { STAKE_TOKEN_CONTRACT, STAKING_CONTRACT } from "../../constants";

const Wallet = ({ children }) => {
  const [state, setState] = useState({
    provider: null,
    selectedAccount: null,
    stakingContract: null,
    stakeTokenContract: null,
    chainId: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const provider = useSelector((state) => state.provider.connection);
  const chainId = useSelector((state) => state.provider.chainId);
  const account = useSelector((state) => state.provider.account);

  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      if (provider && chainId) {
        const signer = await provider.getSigner();
        console.log("account: ", account)
        setState({
          provider: provider,
          selectedAccount: account,
          chainId: chainId,
          stakingContract: new Contract(STAKING_CONTRACT, stakingAbi, signer),
          stakeTokenContract: new Contract(
            STAKE_TOKEN_CONTRACT,
            stakeTokenAbi,
            signer
          ),
        });
      }
    }
    init();
    window.ethereum.on("accountsChanged", () => handleAccountChange(setState));
    window.ethereum.on("chainChanged", () => handleChainChange(setState));
    return () => {
      window.ethereum.removeListener("accountsChanged", () =>
        handleAccountChange(setState)
      );
      window.ethereum.removeListener("chainChanged", () =>
        handleChainChange(setState)
      );
    };
  }, [provider, chainId, account]);
  const handleWallet = async () => {
    try {
      setIsLoading(true);
      const {
        provider,
        selectedAccount,
        signer,
        chainId,
      } = await connectWallet(dispatch);
      setState({
        provider,
        selectedAccount,
        stakingContract: new Contract(STAKING_CONTRACT, stakingAbi, signer),
        stakeTokenContract: new Contract(
          STAKE_TOKEN_CONTRACT,
          stakeTokenAbi,
          signer
        ),
        chainId,
      });
    } catch (error) {
      toast.error("Error connecting wallet");
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="connect-wallet">
      <Web3Context.Provider value={state}>{children}</Web3Context.Provider>
      {isLoading && <p>Loading...</p>}
      {!account && (
        <Button
          className="bg-blue text-white"
          onClick={handleWallet}
          type="button"
          label="Connect Wallet"
        />
      )}
    </div>
  );
};
export default Wallet;
