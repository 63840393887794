import { useState } from "react";

const TokenApproval = () => {
  const [stakeToken, setStakeToken] = useState("KDE");

  // const approveToken = async (e) => {
  //   e.preventDefault();
  //   const amount = approvedTokenRef.current.value.trim();
  //   console.log("stakeTokenContract: ", stakeTokenContract)
  //   console.log("stakingContract: ", stakingContract)

  //   if (isNaN(amount) || amount <= 0) {
  //     console.error("Please enter a valid positive number");
  //     return;
  //   }
  //   const amountToSend = utils.parseUnits(amount, 18).toString();
  //   console.log("amountToSend: ", amountToSend)
  //   try {
  //     const transaction = await stakeTokenContract.approve(
  //       stakingContract.address,
  //       amountToSend
  //     );
  //     await toast.promise(transaction.wait(), {
  //       loading: "Transaction is pending...",
  //       success: "Transaction successful 👌",
  //       error: "Transaction failed 🤯",
  //     });
  //     approvedTokenRef.current.value = "";
  //     // const receipt = await transaction.wait();
  //     // if (receipt.status === 1) {
  //     //     toast.success("Transaction is successful")
  //     //     approvedTokenRef.current.value = "";
  //     //   } else {
  //     //       toast.error("Transaction failed. Please try again.")
  //     //   }
  //   } catch (error) {
  //     toast.error("Token Approval Failed");
  //     console.error(error.message);
  //   }
  // };

  const onChangeStakeToken = (e) => {
    setStakeToken(e.target.value);
  };
  return (
    <div className="token-amount-form">
      <label className="token-input-label">Token:</label>
      <select
        className="select-token"
        value={stakeToken}
        onChange={onChangeStakeToken}
      >
        <option value="KDE">KDE</option>
      </select>
    </div>
  );
};
export default TokenApproval;
