import React, { useState } from "react";
import StakingPannel from "../components/StakingPannel";
import StakeAmount from "../components/StakeToken/StakeAmount";
import TokenApproval from "../components/StakeToken/TokenApproval";
import WithdrawStakeAmount from "../components/Withdraw/Withdraw";
import { StakingProvider } from "../context/StakingContext";
import Wallet from "../components/Wallet/Wallet";
import { useNavigate } from "react-router-dom";

export default function Staking() {
  const [displaySection, setDisplaySection] = useState("stake");
  const _navigate = useNavigate()
  const handleButtonClick = (section) => {
    setDisplaySection(section);
  };

  return (
    <div className="main-section">
      <span 
      className="button-back"
      onClick={() => _navigate("/")}
      >
        {"< Back"}
      </span>
      <Wallet>
        <StakingProvider>
          <StakingPannel />
          <div className="main-content">
            <div className="button-section">
              <button
                onClick={() => handleButtonClick("stake")}
                className={displaySection === "stake" ? "" : "active"}
              >
                Stake
              </button>
              <button
                onClick={() => handleButtonClick("withdraw")}
                className={displaySection === "withdraw" ? "" : "active"}
              >
                Withdraw
              </button>
            </div>
            {displaySection === "stake" && (
              <div className="stake-wrapper">
                <TokenApproval />
                <StakeAmount />
              </div>
            )}
            {displaySection === "withdraw" && (
              <div className="stake-wrapper">
                <WithdrawStakeAmount />
              </div>
            )}
          </div>
        </StakingProvider>
      </Wallet>
    </div>
  );
}
