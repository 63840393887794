import { useContext, useRef } from "react";
import { utils } from "ethers";
import Web3Context from "../../context/Web3Context";
import Button from "../Button/Button";
import StakingContext from "../../context/StakingContext";
import { toast } from "react-hot-toast";
import "./StakeToken.css";

const StakeAmount = () => {
  const { stakingContract, stakeTokenContract } = useContext(Web3Context);
  const { isReload, setIsReload } = useContext(StakingContext);
  const stakeAmountRef = useRef();

  const stakeToken = async (e) => {
    e.preventDefault();
    const amount = stakeAmountRef.current.value.trim();
    if (isNaN(amount) || amount <= 0) {
      toast.error("Please enter a valid positive number.");
      return;
    }
    const amountToStake = utils.parseUnits(amount, 18).toString();
    try {
      // token approval
      const approval = await stakeTokenContract.approve(
        stakingContract.address,
        amountToStake
      );
      await toast.promise(approval.wait(), {
        loading: "Approving...",
        success: "Approval successful 👌",
        error: "Approval failed 🤯",
      });
      const transaction = await stakingContract.stake(amountToStake);
      await toast.promise(transaction.wait(), {
        loading: "Staking...",
        success: "Stake successful 👌",
        error: "Stake failed 🤯",
      });
      stakeAmountRef.current.value = "";
      setIsReload(!isReload);
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <form onSubmit={stakeToken} className="stake-amount-form">
      <label className="stake-input-label">Amount:</label>
      <input type="text" placeholder="0" ref={stakeAmountRef} />
      <Button onClick={stakeToken} type="submit" label="Stake Token" />
    </form>
  );
};
export default StakeAmount;
