import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux';
import closeIcon from '../assets/close.svg';
import { myEventsSelector } from '../store/selectors';

import config from '../config.json';

const Alert = () => {
  const alertRef = useRef(null)

  const network = useSelector(state => state.provider.chainId)
  const account = useSelector(state => state.provider.account)
  const isPending = useSelector(state => state.exchange.transaction.isPending)
  const isSuccessful = useSelector(state => state.exchange.transaction.isSuccessful)
  const isError = useSelector(state => state.exchange.transaction.isError)
  const events = useSelector(myEventsSelector)

  const removeHandler = async (e) => {
    alertRef.current.className = 'alert alert--remove'
  }

  useEffect(() => {
    if ((events[0] || isPending || isError) && account && alertRef?.current) {
      alertRef.current.className = 'alert'
      if(!isPending) {
        const timer = setInterval(() => {
          alertRef.current.className = 'alert alert--remove'
          clearInterval(timer)
        }, 6 * 1000)
      }
    }
  }, [events, isPending, isError, account])

  const ButtonClose = () => (
    <span className='button-close' onClick={removeHandler}>
      <img src={closeIcon} alt="close" width="22px" />
    </span>
  )

  return (
    <div>
      {isPending ? (

        <div className="alert alert--remove" ref={alertRef}>
          <h1>Transaction Pending...</h1>
        </div>

      ) : isError ? (

        <div className="alert alert--remove" ref={alertRef}>
          <ButtonClose />
          <h1 className='text-danger' >Transaction Will Fail</h1>
        </div>

      ) : (isSuccessful && events[0]) ? (

        <div className="alert alert--remove" ref={alertRef}>
          <ButtonClose />
          <h1 className='text-success'>Transaction Successful</h1>
          <a
            href={config[network] ? `${config[network].explorerURL}/tx/${events[0].transactionHash}` : '#'}
            target='_blank'
            rel='noreferrer'
          >
            {events[0].transactionHash.slice(0, 6) + '...' + events[0].transactionHash.slice(60, 66)}
          </a>
        </div>

      ) : (
        <div className="alert alert--remove" ref={alertRef}></div>
      )}
    </div>
  );
}

export default Alert;
