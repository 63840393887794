export const BG_SKELETON = '#232b39'
export const STAKING_CONTRACT = "0x13161bD4f14377bde8290ED038a0B7F0B1021e36"
export const STAKE_TOKEN_CONTRACT = "0xC23Bd5701Bcee429781fba2AE536eEe7C8281610"
export const MY_CHAIN = {
  chainId: 3024376,
  chainName: "Conan",
  nativeCurrency: {
    name: "conan",
    symbol: "CON",
    decimals: 18,
  },
  rpcUrls: ["https://froopyland.dymension.xyz/16/conan_3024376-1/evmrpc"],
  blockExplorerUrls: ["https://explorer.rustcoder.com"],
};
