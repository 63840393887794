import { memo } from "react"
import Skeleton from '@mui/material/Skeleton';
import { BG_SKELETON } from "../../constants";

const TableLoader = () => (
  <table className='exchange__orderbook--sell'>
    <caption><Skeleton variant="text" sx={{ bgcolor: BG_SKELETON }} width={200} height={40} /></caption>
    <thead>
      <tr>
        {Array.from(Array(3)).map((_, index) => (
          <th key={index}><Skeleton variant="rectangular" sx={{ bgcolor: BG_SKELETON }} width={200} height={30} /></th>
        ))}
      </tr>
    </thead>
    <tbody>
      {Array.from(Array(3)).map((_, index) => (
        <tr key={index}>
          {Array.from(Array(3)).map((_, idx) => (
            <th key={idx}><Skeleton variant="rectangular" sx={{ bgcolor: BG_SKELETON }} width={200} height={30} /></th>
          ))}
        </tr>
      )
      )}

    </tbody>
  </table>
)

export default memo(TableLoader)