import { useSelector } from 'react-redux';

import Chart from 'react-apexcharts';

import arrowDown from '../assets/down-arrow.svg';
import arrowUp from '../assets/up-arrow.svg';

import { options, defaultSeries } from './PriceChart.config';

import { priceChartSelector } from '../store/selectors';

import Banner from './Banner';
import { Skeleton } from '@mui/material';
import { BG_SKELETON } from '../constants';

const processChartData = (data) => {
  if (!data || !data.series) {
    return defaultSeries; // Hoặc giá trị mặc định khác nếu cần
  }

  // Xử lý dữ liệu trước khi truyền vào biểu đồ
  const processedSeries = data.series.map((item) => {
    // Kiểm tra và xử lý giá trị không hợp lệ
    const processedData = item.data.map((it) => ({
      x: it?.x,
      y: it?.y?.map(value => isFinite(value) ? value : 0)
    }));
    return {
      ...item,
      data: processedData,
    };
  });

  return {
    ...data,
    series: processedSeries,
  };
};

const PriceChart = () => {
  const account = useSelector(state => state.provider.account)
  const symbols = useSelector(state => state.tokens.symbols)
  const priceChart = useSelector(priceChartSelector)

  return (
    <div className="component exchange__chart">
      <div className='component__header flex-between'>
        <div className='flex'>

          <h2>{symbols ? `${symbols?.[0] ?? '__'}/${symbols?.[1] ?? '__'}` : (
            <>
              <Skeleton variant="text" sx={{ bgcolor: BG_SKELETON }} width={200} height={40} />
              <Skeleton variant="text" sx={{ bgcolor: BG_SKELETON }} width={200} height={40} />
              <Skeleton variant="text" sx={{ bgcolor: BG_SKELETON }} width={200} height={40} />
            </>
          )}</h2>

          {priceChart && (

            <div className='flex'>

              {priceChart.lastPriceChange === '+' ? (
                <img src={arrowUp} alt="Arrow up" />
              ) : (
                <img src={arrowDown} alt="Arrow down" />
              )}

              <span className='up'>{priceChart?.lastPrice ?? '__'}</span>
            </div>

          )}

        </div>
      </div>
      {!account ? (
        <Banner text={'Please connect with Metamask'} />
      ) : priceChart ? (
        <Chart
          type="candlestick"
          options={options}
          series={priceChart ? processChartData(priceChart)?.series : defaultSeries}
          width="100%"
          height="100%"
        />
      ) : (
        <Skeleton variant="rectangular" sx={{ bgcolor: BG_SKELETON }} width={"100%"} height={300} />
      )}

    </div>
  );
}

export default PriceChart;
