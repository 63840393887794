import { useState, useEffect, useContext } from "react";
import Web3Context from "../../context/Web3Context";
import { utils } from "ethers";
import "./style.css";
const RewardRate = () => {
  const { stakingContract, selectedAccount } = useContext(Web3Context);
  const [rewardRate, setRewardRate] = useState("0");

  useEffect(() => {
    const fetchRewardRate = async () => {
      try {
        const rewardRateWei = await stakingContract.REWARD_RATE();
        const rewardRateEth = utils.formatUnits(rewardRateWei.toString(), 18);
        const rewardDay = (rewardRateEth * 24 * 60 * 60).toFixed(4)
        setRewardRate(rewardDay);
      } catch (error) {
        console.error(error.message);
      }
    };
    stakingContract && fetchRewardRate();
  }, [stakingContract, selectedAccount]);

  return (
    <div className="reward-rate">
      <p>Reward Rate:</p>
      <span>{rewardRate} token/day </span>
    </div>
  );
};
export default RewardRate;
