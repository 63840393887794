import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home"
import Staking from "../pages/Staking"

export function Router() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/staking" element={<Staking />} />
      </Routes>
    </Suspense>
  )
}