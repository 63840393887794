import Blockies from 'react-blockies'
import { useDispatch, useSelector } from 'react-redux'

import conan from '../assets/conan.png'
import logo from '../assets/logo.png'

import { useState } from 'react'
import config from '../config.json'
import { faucetAllToken, loadAccount } from '../store/interactions'
import { MY_CHAIN } from '../constants'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const provider = useSelector(state => state.provider.connection)
  const chainId = useSelector(state => state.provider.chainId)
  const account = useSelector(state => state.provider.account)
  const balance = useSelector(state => state.provider.balance)

  const [loading, setLoading] = useState(false)
  const [tx, setTx] = useState(null)
  const [msgFaucet, setMsgFaucet] = useState(null)
  const [faucetStatus, setFaucetStatus] = useState(null)

  const dispatch = useDispatch()
  const _navigate = useNavigate()

  const connectHandler = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        alert("Get MetaMask -> https://metamask.io/");
        return;
      }
      await loadAccount(provider, dispatch)
    } catch (error) {
      console.log(error)
    }
  }
  const faucetHandler = async () => {
    setLoading(true)
    const { tx, errMsg } = await faucetAllToken(provider, chainId, account, setFaucetStatus)
    setLoading(false)
    setTx(tx?.hash)
    setMsgFaucet(tx ? `Faucet successfully` : errMsg)
    const timer = setInterval(() => {
      setMsgFaucet(null)
      clearInterval(timer)
    }, 4 * 1000)
    setFaucetStatus(null)
    await loadAccount(provider, dispatch)
  }

  const networkHandler = async (e) => {
    // await window.ethereum.request({
    //   method: 'wallet_switchEthereumChain',
    //   params: [{ chainId: e.target.value, name: "Conan" }],
    // })
    const { ethereum } = window
    if (ethereum) {
      try {
        // Try to switch to the Conan testnet
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{
            chainId: `0x${MY_CHAIN.chainId.toString(16)}`,
          }],
        });
        window.location.reload()
      } catch (error) {
        // This error code means that the chain we want has not been added to MetaMask
        // In this case we ask the user to add it to their MetaMask
        if (error.code === 4902) {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [{
                ...MY_CHAIN,
                chainId: `0x${MY_CHAIN.chainId.toString(16)}`
              }],
            });
          } catch (error) {
            console.log(error);
          }
        }
        console.log(error);
      }
    } else {
      // If window.ethereum is not found then MetaMask is not installed
      alert(
        "MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html"
      );
    }
  }

  return (
    <div className='exchange__header grid'>
      <div className='exchange__header--brand flex'>
        <img src={logo} className="logo" alt="DEX Logo"></img>
        <h1>Kong DEX</h1>
      </div>

      <div className='exchange__header--networks flex'>
        <img src={conan} alt="Conan Logo" className='Eth Logo' />

        {chainId && (
          <select name="networks" id="networks" value={config[chainId] ? `0x${chainId.toString(16)}` : `0x2e25f8`} onChange={networkHandler}>
            {/* <option value="0" disabled>Select Network</option>
            <option value="0x7A69">Localhost</option> */}
            <option value="0x2e25f8">Conan</option>
          </select>
        )}
        <button
          className="button-staking"
          onClick={() => _navigate("/staking")}
        >
          Staking
        </button>
      </div>

      <div className='exchange__header--account flex'>
        {balance ? (
          <p><small>My Balance</small>{Number(balance).toFixed(4)}</p>
        ) : (
            <p><small>My Balance</small>0 CON</p>
          )}
        {account ? (
          <a
            href={config[chainId] ? `${config[chainId].explorerURL}/address/${account}` : `#`}
            target='_blank'
            rel='noreferrer'
          >
            {account.slice(0, 5) + '...' + account.slice(38, 42)}
            <Blockies
              seed={account}
              size={10}
              scale={3}
              color="#2187D0"
              bgColor="#F1F2F9"
              spotColor="#767F92"
              className="identicon"
            />
          </a>
        ) : (chainId !== MY_CHAIN.chainId) ? (
          <button className="button" onClick={networkHandler}>Switch Network</button>
        ) : (
              <button className="button" onClick={connectHandler}>Connect</button>
            )}
        <button className="button" style={{ width: '100px', marginLeft: '10px' }} disabled={loading} onClick={faucetHandler}>
          {faucetStatus ?? "Faucet"}
          {loading && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '7px' }} />}
        </button>
        {msgFaucet && (
          <div className={`msg-faucet text-${tx ? 'success' : 'danger'}`}>
            {msgFaucet ?? ""}
          </div>
        )}
      </div>

    </div>
  )
}

export default Navbar;

