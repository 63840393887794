import { loadAccount, loadNetwork, loadProvider } from "../store/interactions";

export const connectWallet = async (dispatch) => {
  try {
    let [signer, provider, chainId] = [null, null, null];
    if (window.ethereum === null) {
      throw new Error("Metamask is not installed");
    }

    provider = await loadProvider(dispatch);
    const chainIdHex = await loadNetwork(provider, dispatch);
    const account = await loadAccount(provider, dispatch);
  
    // const accounts = await window.ethereum.request({
    //   method: 'eth_requestAccounts'
    // })

    // let chainIdHex = await window.ethereum.request({
    //   method: 'eth_chainId'
    // })
    chainId = parseInt(chainIdHex, 16)

    let selectedAccount = account;
    if (!selectedAccount) {
      throw new Error("No ethereum accounts available")
    }

    // provider = new providers.Web3Provider(window.ethereum);
    signer = await provider.getSigner();

    return { provider, selectedAccount, signer, chainId }

  } catch (error) {
    console.error(error);
    throw error
  }

}

export const handleChainChange=async(setState)=>{
  let chainIdHex = await window.ethereum.request(
      {method:'eth_chainId'}
  )
  const chainId = parseInt(chainIdHex,16);
  console.log(chainId)
  setState(prevState=>({...prevState,chainId}))
}

export const handleAccountChange = async(setState)=>{
  const accounts = await window.ethereum.request({
      method:"eth_requestAccounts"
  })
  const selectedAccount = accounts[0];
  console.log(selectedAccount)
  setState(prevState=>({...prevState,selectedAccount}))
}